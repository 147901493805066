"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tryCatch = function (fn, default_, onError, onSuccess) {
    try {
        var resp = fn();
        if (onSuccess != null)
            onSuccess(resp);
        return resp;
    }
    catch (error) {
        if (onError != null)
            onError(error);
        return default_;
    }
};
exports.wrapP = function (promise) {
    return promise.then(function (res) { return [res, null]; }).catch(function (err) { return [null, err]; });
};
